// Funkcja do dodawania Google Analytics (gtag.js)
export const addGTag = (taggingServer, gtagId) => {
  // Utworzenie skryptu dla gtag.js
  const scriptTag = document.createElement('script');
  scriptTag.async = true;
  scriptTag.src = `${taggingServer}gtag/js?id=${gtagId}`;
  document.head.appendChild(scriptTag);

  // Utworzenie skryptu inicjalizującego gtag
  const scriptTagInit = document.createElement('script');
  const scriptContent = document.createTextNode(`
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
  
      gtag('config', '${gtagId}', {
        server_container_url: '${taggingServer}',
        allow_google_signals: true
      });
    `);
  scriptTagInit.appendChild(scriptContent);
  document.head.appendChild(scriptTagInit);

  console.log(`Added gtag code for ${gtagId} for server ${taggingServer}`);
};
